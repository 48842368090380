import "./App.css";
import { Lands } from "./lands";

function App() {
  return (
    <div className="App">
      <header className="App-header">Pixels Land</header>
      <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Lands />
        </div>
        {/* <div>aaaa</div> */}
      </div>
    </div>
  );
}

export default App;
