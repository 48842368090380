import { useEffect, useRef, useState } from "react";

export const Lands = () => {
  const [lands, setLands] = useState<number[]>([]);
  const [selected, setSelected] = useState<number | null>(null);
  useEffect(() => {
    loadMoreLands();
  }, []);

  // generate 2 random numbers between 1 and 5000 that
  // isn't already in the lands array
  const loadMoreLands = () => {
    const newLands = [];
    for (let i = 0; i < 2; i++) {
      let newLand;
      do {
        newLand = Math.floor(Math.random() * 5000) + 1;
      } while (lands?.includes(newLand));
      newLands.push(newLand);
    }
    setLands([...(lands || []), ...newLands]);
  };
  if (lands.length === 0) return <div>No lands</div>;
  return (
    <>
      <div className="container">
        {lands.map((land) => (
          <Land key={land} landNumber={land} onSelect={setSelected} />
        ))}
      </div>
      <div className="more-lands">
        <button onClick={loadMoreLands}>Load more</button>
      </div>
      <Modal isOpen={selected !== null} onClose={() => setSelected(null)}>
        <div style={{ aspectRatio: 1 / 1 }}>
          <Land
            selected={true}
            landNumber={selected!}
            onSelect={(land) => {
              // if the land is selected, close the modal
              if (land === selected) return setSelected(null);
              setSelected(land);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

const Land = ({
  landNumber,
  onSelect,
  selected,
}: {
  landNumber: number;
  onSelect: (land: number) => void;
  selected?: boolean;
}) => {
  const ref = useRef<HTMLIFrameElement>(null);
  return (
    <div className="land">
      <iframe
        ref={ref}
        src={`https://play.pixels.xyz/pixels/share/${landNumber}`}
        title={`Land ${landNumber}`}
      ></iframe>
      <div>
        <div
          style={{
            fontSize: "20px",

            padding: 10,
            textAlign: "center",
            fontWeight: "bold",
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              padding: 10,
              backgroundColor: "var(--purple)",
              height: "fit-content",
              borderRadius: "5px",
            }}
            onClick={() => {
              // copy to clipboard landNumber
              window.navigator.clipboard.writeText(`Land ${landNumber}`);
              alert(`Land ${landNumber} copied to clipboard`);
            }}
          >
            Land {landNumber}{" "}
          </button>

          <button
            style={{
              height: "fit-content",
              marginRight: "20px",
              display: "none",
            }}
          >
            Save
          </button>
        </div>
        <button
          style={{
            backgroundColor: "var(--purple)",
            borderRadius: "5px",
            bottom: "10px",
            left: "10px",
            position: "absolute",
            aspectRatio: 1 / 1,
          }}
          onClick={() => onSelect(landNumber)}
        >
          {selected ? "x" : "[ ]"}
        </button>
        <button
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={() => {
            const src = ref.current?.src;
            // the idea is to reload the iframe by changing the src
            if (src) {
              ref.current!.src = src;
            }
          }}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

function Modal({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000, // Asegúrate de que sea más alto que otros elementos
      }}
    >
      {/* <button onClick={onClose}>Cerrar</button> */}
      <div
        style={{
          backgroundColor: "transparent",
          padding: "20px",
          borderRadius: "5px",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        {children}
      </div>
    </div>
  );
}
